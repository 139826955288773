.OnboardingPage {
  display: grid;

  padding-block: 4rem;
  padding-inline: 3rem;
}

.LinkAccountsContainer {
  display: grid;

  padding: 1rem;
  border-radius: 1rem;

  max-inline-size: 80rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}