.accounting-layout .accounting-layout__nav {
  display: flex;
  gap: var(--spacing-md);
  width: 100%;
}

.accounting-layout .accounting-layout__topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height:76px;
  border-bottom: 1px solid #EBEBEB;
  background: #fff;
  padding-left: 24px;
}

.accounting-layout__topbar__right {
  padding-left: var(--spacing-lg);
}

.topbar-logo-container {
  border: none;
  padding: 16px;
  text-align: left;
  border-radius: 12px;
  max-height: 52px;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--base-0, #fff);

  box-shadow: 0px 4px 4px 0px rgba(19, 19, 22, 0.03), 0px 1px 1px -0.5px rgba(19, 20, 22, 0.03),
    0px 1px 0px -1px rgba(19, 20, 22, 0.03);
  transition: all 0.05s ease-in-out;

}

.accounting-layout__topbar__right button {
  outline: none;
  text-decoration: none;
  color: #848382;
  background: transparent;
  border-radius: 4px;
  border: 1px solid #EEE;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  display: none;
  align-items: center;
  gap: var(--spacing-xs);
  font-size: 11px;
  line-height: 14px;
  transition: all 150ms ease-out;
  cursor: pointer;
}

.topbar-logo-container {
  justify-content: space-between;
  max-width: 150px;
}

.topbar-logout-button {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  padding: 0 12px;
  border-radius: 4px;
  height: 32px;
  width: max-content !important;
  white-space: nowrap;
}

.accounting-layout__topbar__right button:hover {
  border-color: var(--color-accent);
  background-color: var(--color-accent-light);
}

@media (max-width: 992px) {
  .accounting-layout__topbar__right button {
    display: flex;
  }
}

.accounting-layout .accounting-layout__content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.accounting-layout .Layer__view .Layer__view-header {
  background-color: #fff;
}