body {
  margin: 0;
  font-family: var(--font-family);
  font-feature-settings:
    'cv10' on,
    'cv01' on,
    'cv05' on,
    'cv08' on,
    'ss03' on,
    'clig' off,
    'liga' off;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  font-family: Inter, sans-serif;
  font-feature-settings:
    'liga' 1,
    'calt' 1;
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: InterVariable, sans-serif;
  }
}

.structure {
  min-height: 100vh;
  font-size: 16px;
  display: flex;
}

.main-content {
  background-color: var(--background-color);
  padding: 0;
  flex: 1;
  box-sizing: border-box;
  padding-left: 250px;
}

.sidebar--open .main-content {
  padding-left: 250px;
}

.sidebar--hidden .main-content {
  padding-left: 0;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #e2e2e2;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #999;
}

@media (max-width: 992px) {
  .structure .main-content {
    padding-left: 0;
    padding-bottom: 68px;
  }
}

.accounting-layout__content .Layer__component .recharts-responsive-container {
  box-shadow: none;
}

.Layer__accounting-overview-profit-and-loss-chart {
  width: 100%;
  box-sizing: border-box;
}

.accounting-overview-profit-and-loss-charts {
  display: flex;
  align-items: stretch;
  width: 100%;
  gap: var(--spacing-md);
  box-sizing: border-box;
  max-width: 1406px;
}

.Layer__reports .Layer__panel__sidebar .Layer__panel__sidebar-content {
  width: 100%;
}

.admin-button {
  padding: 5px;
  width: 100%;
  border-radius: 4px;
  border-width: 1px;
  background-color: #eee;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 3px;
}