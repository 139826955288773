.sidebar {
  border-right: 1px solid #efeff1;
  width: 250px;
  background-color: #f7f8fa;
  z-index: 10;
  max-height: 100vh;
  position: fixed;
  box-shadow: 8px 4px 21.6px 0px rgba(0, 0, 0, 0.03);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
  height: 100vh;
  box-sizing: border-box;
}

.sidebar-header {
  width: 100%;
  margin-bottom: 12px;
  position: relative;
  z-index: 2;
}

.sidebar ul {
  padding: 12px 0 0 0;
  margin-top: 64px;
  border-top: rgba(239, 239, 241, 1) 1px solid;
}

.sidebar li {
  list-style-type: none;
  white-space: nowrap;
}

.sidebar .nav-link {
  display: flex;
  align-items: center;
  height: 34px;
  padding: 4px 8px;
  text-decoration: none;
  font-size: 14px;
  border-radius: 8px;
  color: rgba(5, 19, 47, 0.56);
  cursor: default;
}

.sidebar .nav-link svg {
  margin-right: var(--spacing-2xs);
}


.sidebar .nav-link.active {
  color: #05132F;
  background: #f0f0f2;
}

.sidebar-close-btn {
  display: none;
  position: absolute;
  top: 24px;
  right: 24px;
  outline: none;
  background: transparent;
  border-width: 0;
  cursor: pointer;
}

@media (max-width: 992px) {
  .sidebar {
    box-sizing: border-box;
    position: fixed;
    left: 0;
    right: 0;
    top: 50px;
    width: 100vw;
    min-height: 100vh;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 300ms ease-out, top 300ms ease-out;
    z-index: -1;
  }

  .structure.sidebar--open {
    max-height: 100vh;
    overflow: hidden;
  }

  .structure.sidebar--open .sidebar-close-btn {
    display: flex;
  }

  .sidebar--open .sidebar {
    opacity: 1;
    z-index: 100;
    top: 0;
  }

  .sidebar--open .sidebar-content {
    padding: 36px 0;
  }
}

.switch-theme-wrapper {
  position: absolute;
  width: 100%;
  border-radius: 12px;
  box-shadow: 0px 4px 4px 0px rgba(19, 19, 22, 0.03), 0px 1px 1px -0.5px rgba(19, 20, 22, 0.03),
    0px 1px 0px -1px rgba(19, 20, 22, 0.03);
}

.switch-theme-wrapper-open {
  border: 1px solid rgba(19, 19, 19, 0.04);
}

.switch-theme-button {
  border: none;
  padding: 16px;
  text-align: left;
  border-radius: 12px;
  max-height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--base-0, #fff);

  box-shadow: 0px 4px 4px 0px rgba(19, 19, 22, 0.03), 0px 1px 1px -0.5px rgba(19, 20, 22, 0.03),
    0px 1px 0px -1px rgba(19, 20, 22, 0.03);
  transition: all 0.05s ease-in-out;

}

.sidebar-bg {
  visibility: hidden;
  display: none;
}

.sidebar-bg-open {
  position: absolute;
  background-color: rgba(248, 248, 250, 0.5);
  width: calc(100% - 24px);
  height: 100vh;
  visibility: visible;
  display: block;
  z-index: 1;
}

.brand-logo {
  width: auto;
  max-width: 100%;
  height: 30px;
}

